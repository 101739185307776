export default [
  {
    path: '/settings/agents',
    name: 'settings-agents',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/settings/agents/AgentPage'),
    meta: {
      pageTitle: 'Agentes',
      breadcrumb: [
        {
          text: 'Agentes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/agents/create',
    name: 'settings-agents-create',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/settings/agents/AgentCreatePage'),
    meta: {
      pageTitle: 'Agentes',
      breadcrumb: [
        {
          text: 'Listado de Agentes',
        },
        {
          text: 'Crear',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/agents/edit/:id',
    name: 'settings-agents-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/settings/agents/AgentEditPage'),
    props: route => {
      const id = Number(route.params.id)
      return isNaN(id) ? { id: 1 } : { id }
    },
    meta: {
      pageTitle: 'Agentes',
      breadcrumb: [
        {
          text: 'Listado de Agentes',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
]
