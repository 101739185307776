export default [
  {
    path: '/settings/categories',
    name: 'settings-categories',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/settings/categories/CategoryPage'),
    meta: {
      pageTitle: 'Categorías',
      breadcrumb: [
        {
          text: 'Categorías',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/categories/create',
    name: 'settings-categories-create',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/settings/categories/CategoryCreatePage'),
    meta: {
      pageTitle: 'Categorías',
      breadcrumb: [
        {
          text: 'Listado de Categorías',
        },
        {
          text: 'Crear',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/categories/edit/:id',
    name: 'settings-categories-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/settings/categories/CategoryEditPage'),
    props: route => {
      const id = Number(route.params.id)
      return isNaN(id) ? { id: 1 } : { id }
    },
    meta: {
      pageTitle: 'Categorías',
      breadcrumb: [
        {
          text: 'Listado de Categorías',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
]
