export default [
  {
    path: '/settings/companies',
    name: 'settings-companies',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/settings/companies/CompanyPage'),
    meta: {
      pageTitle: 'Compañías',
      breadcrumb: [
        {
          text: 'Compañías',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/companies/create',
    name: 'settings-companies-create',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/settings/companies/CompanyCreatePage'),
    meta: {
      pageTitle: 'Compañías',
      breadcrumb: [
        {
          text: 'Listado de Compañías',
        },
        {
          text: 'Crear',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/companies/edit/:id',
    name: 'settings-companies-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/settings/companies/CompanyEditPage'),
    props: route => {
      const id = Number(route.params.id)
      return isNaN(id) ? { id: 1 } : { id }
    },
    meta: {
      pageTitle: 'Compañías',
      breadcrumb: [
        {
          text: 'Listado de Compañías',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
]
