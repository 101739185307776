export default [
    {
        path: '/passwords',
        name: 'passwords',
        // eslint-disable-next-line import/extensions
        component: () => import('@/pages/dashboard/passwords/PasswordPage'),
        meta: {
            pageTitle: 'Contraseñas',
            breadcrumb: [
                {
                    text: 'Cliente CRSoporte',
                    active: false,
                },
            ],
        },
    },
]
