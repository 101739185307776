export default [
  {
    path: '/workshops/brands',
    name: 'workshops-brands',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/workshops/brands/BrandPage'),
    meta: {
      pageTitle: 'Marcas',
      breadcrumb: [
        {
          text: 'Listado de Marcas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/workshops/brands/create',
    name: 'workshops-brands-create',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/workshops/brands/BrandCreatePage'),
    meta: {
      pageTitle: 'Marcas',
      breadcrumb: [
        {
          text: 'Listado de Marcas',
        },
        {
          text: 'Crear',
          active: true,
        },
      ],
    },
  },
  {
    path: '/workshops/brands/edit/:id',
    name: 'workshops-brands-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/workshops/brands/BrandEditPage'),
    props: route => {
      const id = Number(route.params.id)
      return isNaN(id) ? { id: 1 } : { id }
    },
    meta: {
      pageTitle: 'Marcas',
      breadcrumb: [
        {
          text: 'Listado de Marcas',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
]
