export default [
	{
		path: '/designers/access',
		name: 'designers-access',
		// eslint-disable-next-line import/extensions
		component: () => import('@/pages/dashboard/designers/access/AccessPage'),
		meta: {
			pageTitle: 'Accesos',
			breadcrumb: [
				{
					text: 'Listado de Accesos',
					active: true,
				},
			],
		},
	},
]
