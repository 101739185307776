export default [
  {
    path: '/workshops/equipments',
    name: 'workshops-equipments',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/workshops/equipments/EquipmentPage'),
    meta: {
      pageTitle: 'Equipos',
      breadcrumb: [
        {
          text: 'Listado de Equipos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/workshops/equipments/create',
    name: 'workshops-equipments-create',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/workshops/equipments/EquipmentCreatePage'),
    meta: {
      pageTitle: 'Equipos',
      breadcrumb: [
        {
          text: 'Listado de Equipos',
        },
        {
          text: 'Crear',
          active: true,
        },
      ],
    },
  },
  {
    path: '/workshops/equipments/edit/:id',
    name: 'workshops-equipments-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/workshops/equipments/EquipmentEditPage'),
    props: route => {
      const id = Number(route.params.id)
      return isNaN(id) ? { id: 1 } : { id }
    },
    meta: {
      pageTitle: 'Equipos',
      breadcrumb: [
        {
          text: 'Listado de Equipos',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
]
