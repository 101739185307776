export default [
    {
        path: '/settings/profile/',
        name: 'settings-profile',
        // eslint-disable-next-line import/extensions
        component: () => import('@/pages/dashboard/settings/profile/ProfileEditPage'),
        meta: {
            pageTitle: 'Perfil',
            breadcrumb: [
                {
                    text: 'Perfil',
                },
                {
                    text: 'Editar',
                    active: true,
                },
            ],
        },
    },
]
