export default [
  {
    path: '/main',
    name: 'main',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/main/MainPage'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
]
