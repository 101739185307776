export default [
  {
    path: '/settings/config',
    name: 'settings-config',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/settings/config/ConfigPage'),
    meta: {
      pageTitle: 'Configuración',
      breadcrumb: [
        {
          text: 'Configuración',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/settings/config/create',
  //   name: 'settings-config-create',
  //   // eslint-disable-next-line import/extensions
  //   component: () => import('@/pages/dashboard/settings/config/ConfigCreatePage'),
  //   meta: {
  //     pageTitle: 'Configuración',
  //     breadcrumb: [
  //       {
  //         text: 'Listado de Configuración',
  //       },
  //       {
  //         text: 'Crear',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/settings/config/edit/:id',
    name: 'settings-config-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/settings/config/ConfigEditPage'),
    props: route => {
      const id = Number(route.params.id)
      return isNaN(id) ? { id: 1 } : { id }
    },
    meta: {
      pageTitle: 'Configuración',
      breadcrumb: [
        {
          text: 'Listado de Configuración',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
]
