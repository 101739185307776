export default [
  {
    path: '/tickets',
    name: 'tickets',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/tickets/TicketPage'),
    props: route => ({ status: 'all' }),
    meta: {
      pageTitle: 'Tickets',
      breadcrumb: [
        {
          text: 'Listado de Tickets',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tickets/in-progress',
    name: 'tickets-in-progress',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/tickets/TicketPage'),
    props: route => ({ status: 'in-progress' }),
    meta: {
      pageTitle: 'Tickets en Progreso',
      breadcrumb: [
        {
          text: 'Listado de Tickets en Progreso',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tickets/on-hold',
    name: 'tickets-on-hold',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/tickets/TicketPage'),
    props: route => ({ status: 'on-hold' }),
    meta: {
      pageTitle: 'Tickets Pendientes',
      breadcrumb: [
        {
          text: 'Listado de Tickets Pendientes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tickets/finished',
    name: 'tickets-finished',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/tickets/TicketPage'),
    props: route => ({ status: 'closed' }),
    meta: {
      pageTitle: 'Tickets Cerrados',
      breadcrumb: [
        {
          text: 'Listado de Tickets Cerrados',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tickets/create',
    name: 'tickets-create',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/tickets/TicketCreatePage'),
    meta: {
      pageTitle: 'Tickets',
      breadcrumb: [
        {
          text: 'Listado de Tickets',
        },
        {
          text: 'Crear',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tickets/edit/:id',
    name: 'tickets-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/tickets/TicketEditPage'),
    props: route => {
      const id = Number(route.params.id)
      return isNaN(id) ? { id: 1 } : { id }
    },
    meta: {
      pageTitle: 'Tickets',
      breadcrumb: [
        {
          text: 'Listado de Tickets',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
]
