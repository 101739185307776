import Vue from 'vue'
import { ToastPlugin, ModalPlugin, VBModal } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueJwtDecode from 'vue-jwt-decode'
import VueTheMask from 'vue-the-mask'
import Ripple from 'vue-ripple-directive'
import { localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import 'animate.css'
import '@core/scss/vue/libs/vue-select.scss'
import '@core/scss/vue/libs/vue-sweetalert.scss'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import '@core/scss/vue/libs/vue-wizard.scss'
import '@/libs/sweet-alerts'
import '@/libs/portal-vue'
import '@/libs/toastification'

// localize
localize({
  es,
})
localize('es')

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.component(VueTheMask)

Vue.use(VueJwtDecode)
// Composition API

Vue.use(VueCompositionAPI)
// Directives
Vue.directive('ripple', Ripple)
Vue.directive('b-modal', VBModal)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
