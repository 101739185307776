export default [
  {
    path: '/auth/login',
    name: 'login',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/auth/LoginPage'),
    meta: {
      layout: 'full',
    },
  },
]
