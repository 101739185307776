export default [
    {
        path: '/settings/path/',
        name: 'settings-path',
        // eslint-disable-next-line import/extensions
        component: () => import('@/pages/dashboard/settings/path/PathEditPage'),
        meta: {
            pageTitle: 'Path',
            breadcrumb: [
                {
                    text: 'Path',
                },
                {
                    text: '',
                    active: true,
                },
            ],
        },
    },
]
