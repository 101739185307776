export default [
	{
		path: '/designers/devices',
		name: 'designers-devices',
		// eslint-disable-next-line import/extensions
		component: () => import('@/pages/dashboard/designers/devices/DevicesPage'),
		meta: {
			pageTitle: 'Dispositivos',
			breadcrumb: [
				{
					text: 'Listado de Dispositivos',
					active: true,
				},
			],
		},
	},
]
