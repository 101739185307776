export default [
	{
		path: '/designers/providers',
		name: 'designers-providers',
		// eslint-disable-next-line import/extensions
		component: () => import('@/pages/dashboard/designers/providers/ProviderPage'),
		meta: {
			pageTitle: 'Proveedores',
			breadcrumb: [
				{
					text: 'Listado de Proveedores',
					active: true,
				},
			],
		},
	},
]
