export default [
  {
    path: '/workshops/orders',
    name: 'workshops-orders',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/workshops/orders/OrderPage'),
    meta: {
      pageTitle: 'Órdenes',
      breadcrumb: [
        {
          text: 'Listado de Órdenes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/workshops/orders/create',
    name: 'workshops-orders-create',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/workshops/orders/OrderCreatePage'),
    meta: {
      pageTitle: 'Órdenes',
      breadcrumb: [
        {
          text: 'Listado de Órdenes',
        },
        {
          text: 'Crear',
          active: true,
        },
      ],
    },
  },
  {
    path: '/workshops/orders/edit/:id',
    name: 'workshops-orders-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/workshops/orders/OrderEditPage'),
    props: route => {
      const id = Number(route.params.id)
      return isNaN(id) ? { id: 1 } : { id }
    },
    meta: {
      pageTitle: 'Órdenes',
      breadcrumb: [
        {
          text: 'Listado de Órdenes',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
]
