export default [
  {
    path: '/workshops/accessories',
    name: 'workshops-accessories',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/workshops/accessories/AccessoryPage'),
    meta: {
      pageTitle: 'Accesorios',
      breadcrumb: [
        {
          text: 'Listado de Accesorios',
          active: true,
        },
      ],
    },
  },
  {
    path: '/workshops/accessories/create',
    name: 'workshops-accessories-create',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/workshops/accessories/AccessoryCreatePage'),
    meta: {
      pageTitle: 'Accesorios',
      breadcrumb: [
        {
          text: 'Listado de Accesorios',
        },
        {
          text: 'Crear',
          active: true,
        },
      ],
    },
  },
  {
    path: '/workshops/accessories/edit/:id',
    name: 'workshops-accessories-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/workshops/accessories/AccessoryEditPage'),
    props: route => {
      const id = Number(route.params.id)
      return isNaN(id) ? { id: 1 } : { id }
    },
    meta: {
      pageTitle: 'Accesorios',
      breadcrumb: [
        {
          text: 'Listado de Accesorios',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
]
