import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'
import LoginRouter from '@/pages/auth/router/LoginRouter'
import Error404Router from '@/pages/error/router/Error404Router'
import MainRouter from '@/pages/dashboard/main/router/MainRouter'
import PasswordRouter from '@/pages/dashboard/passwords/router/PasswordRouter'
import CustomerRouter from '@/pages/dashboard/customers/router/CustomerRouter'
import CustomerSearchAdvancedRouter from '@/pages/dashboard/customers-search-advanced/router/CustomerSearchAdvancedRouter'
import DesignerBrandRouter from '@/pages/dashboard/designers/brands/router/BrandRouter'
import DesignerDeviceRouter from '@/pages/dashboard/designers/devices/router/DeviceRouter'
import DesignerDeviceAccess from '@/pages/dashboard/designers/access/router/AccessRouter'
import DesignerDeviceRemote from '@/pages/dashboard/designers/remotes/router/RemoteRouter'
import DesignerProviderRouter from '@/pages/dashboard/designers/providers/router/ProviderRouter'
import TicketRouter from '@/pages/dashboard/tickets/router/TicketRouter'
import OrderRouter from '@/pages/dashboard/workshops/orders/router/OrderRouter'
import AccessoryRouter from '@/pages/dashboard/workshops/accessories/router/AccessoryRouter'
import BrandRouter from '@/pages/dashboard/workshops/brands/router/BrandRouter'
import EquipmentRouter from '@/pages/dashboard/workshops/equipments/router/EquipmentRouter'
import ConfigRouter from '@/pages/dashboard/settings/config/router/ConfigRouter'
import CategoryRouter from '@/pages/dashboard/settings/categories/router/CategoryRouter'
import CompanyRouter from '@/pages/dashboard/settings/companies/router/CompanyRouter'
import RoleRouter from '@/pages/dashboard/settings/roles/router/RoleRouter'
import AgentRouter from '@/pages/dashboard/settings/agents/router/AgentRouter'
import UserRouter from '@/pages/dashboard/settings/users/router/UserRouter'
import PathRouter from '@/pages/dashboard/settings/path/router/PathRouter'
import ProfileRouter from '@/pages/dashboard/settings/profile/router/ProfileRouter'
import PublicTicketRouter from '@/pages/public/tickets/router/PublicTicketRouter'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'main' },
    },
    ...LoginRouter,
    ...Error404Router,
    ...MainRouter,
    ...PasswordRouter,
    ...CustomerRouter,
    ...CustomerSearchAdvancedRouter,
    ...DesignerBrandRouter,
    ...DesignerDeviceRouter,
    ...DesignerDeviceAccess,
    ...DesignerDeviceRemote,
    ...DesignerProviderRouter,
    ...TicketRouter,
    ...OrderRouter,
    ...AccessoryRouter,
    ...BrandRouter,
    ...EquipmentRouter,
    ...ConfigRouter,
    ...CategoryRouter,
    ...CompanyRouter,
    ...RoleRouter,
    ...AgentRouter,
    ...UserRouter,
    ...PathRouter,
    ...ProfileRouter,
    ...PublicTicketRouter,
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (to.name !== 'login' && to.name !== 'public-tickets' && to.name !== 'public-tickets-search') {
    if (!canNavigate(to)) {
      // Redirect to login if not logged in
      // if (!isLoggedIn && to.name.indexOf('publicTickets') !== -1) {
      //   return next({ name: 'publicTickets', params: { company: to.params.company } })
      // }
      // //
      // if (!isLoggedIn && to.name.indexOf('publicTickets') !== -1) {
      //   return next({ name: 'publicTicketsSearch', params: { company: to.params.company, token: to.params.token } })
      // }

      if (!isLoggedIn) return next({ name: 'login' })
      // // If logged in => not authorized
      // return next({ name: 'misc-not-authorized' })
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData()
      next(getHomeRouteForLoggedInUser())
    }
  }
  // else if (isLoggedIn) {
  //   return next({ name: 'main' })
  // }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
