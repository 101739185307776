import jwtDefaultConfig from './jwtDefaultConfig'
import router from '@/router'
import VueJwtDecode from 'vue-jwt-decode'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = {...jwtDefaultConfig}

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig}

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
        config => {
          // Get token from localStorage
          const accessToken = this.getToken()

          // Get token from localStorage
          const company = this.getCompanySelected()

          // If token is present add it to request's Authorization Header
          if (accessToken) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          }

          if (company) {
            // eslint-disable-next-line no-param-reassign
            config.headers['X-Company'] = company.id
          }
          return config
        },
        error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
        response => response,
        error => {
          // const { config, response: { status } } = error
          // const { config, response } = error
          const {response} = error
          // const originalRequest = config

          // if (status === 401) {
          if (response && response.status === 401) {
            if (response.config.url !== '/auth/login') {
              this.logout()
              router.replace('auth/login')
            }
            // if (!this.isAlreadyFetchingAccessToken) {
            //   this.isAlreadyFetchingAccessToken = true
            //   this.refreshToken().then(r => {
            //     console.log(r, 'r');
            //     this.isAlreadyFetchingAccessToken = false
            //
            //     // Update accessToken in localStorage
            //     this.setToken(r.data.access_token)
            //     this.setRefreshToken(r.data.access_token)
            //
            //     // this.onAccessTokenFetched(r.data.access_token)
            //   })
            // }
            // const retryOriginalRequest = new Promise(resolve => {
            //   this.addSubscriber(accessToken => {
            //     // Make sure to assign accessToken according to your response.
            //     // Check: https://pixinvent.ticksy.com/ticket/2413870
            //     // Change Authorization header
            //     originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
            //     resolve(this.axiosIns(originalRequest))
            //   })
            // })
            // return retryOriginalRequest
          }
          return Promise.reject(error)
        },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setUserData(value) {
    localStorage.setItem('userData', JSON.stringify(value))
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  me() {
    return this.axiosIns.post(this.jwtConfig.meEndpoint)
  }

  login(...args) {
    if(process.env.VUE_APP_DEBUG_CAPTCHA) {
      if(process.env.VUE_APP_DEBUG_2FA) {
        return this.axiosIns.post(this.jwtConfig.loginEndpoint+`?debug_captcha=${process.env.VUE_APP_DEBUG_CAPTCHA}&debug_2fa=${process.env.VUE_APP_DEBUG_2FA}`, ...args)
      }
      return this.axiosIns.post(this.jwtConfig.loginEndpoint+`?debug_captcha=${process.env.VUE_APP_DEBUG_CAPTCHA}`, ...args)
    }

    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  async logout() {
    // Remove userData from localStorage
    // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    localStorage.clear()
  }

  getCompanies() {
    return JSON.parse(localStorage.getItem('companies'))
  }

  setCompanies(value) {
    localStorage.setItem('companies', JSON.stringify(value))
  }

  getCompanySelected() {
    return JSON.parse(localStorage.getItem('companySelected'))
  }

  setCompanySelected(value) {
    localStorage.setItem('companySelected', JSON.stringify(value))
  }
}
