export default [
  {
    path: '*',
    name: 'error-404',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/error/Error404'),
    meta: {
      pageTitle: 'Error 404',
      breadcrumb: [
        {
          text: 'Error 404',
          active: true,
        },
      ],
    },
  },
]
