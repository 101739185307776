export default [
	{
		path: '/designers/remotes',
		name: 'designers-remotes',
		// eslint-disable-next-line import/extensions
		component: () => import('@/pages/dashboard/designers/remotes/RemotesPage'),
		meta: {
			pageTitle: 'Remotos',
			breadcrumb: [
				{
					text: 'Listado de Remotos',
					active: true,
				},
			],
		},
	},
]
