export default [
  {
    path: '/customers',
    name: 'customers',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/customers/CustomerPage'),
    meta: {
      pageTitle: 'Clientes',
      breadcrumb: [
        {
          text: 'Listado de Clientes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/create',
    name: 'customers-create',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/customers/CustomerCreatePage'),
    meta: {
      pageTitle: 'Clientes',
      breadcrumb: [
        {
          text: 'Listado de Clientes',
        },
        {
          text: 'Crear',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/edit/:id',
    name: 'customers-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/customers/CustomerEditPage'),
    props: route => {
      const id = Number(route.params.id)
      return isNaN(id) ? { id: 1 } : { id }
    },
    meta: {
      pageTitle: 'Clientes',
      breadcrumb: [
        {
          text: 'Listado de Clientes',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
]
