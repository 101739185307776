export default [
	{
		path: '/designers/brands',
		name: 'designers-brands',
		// eslint-disable-next-line import/extensions
		component: () => import('@/pages/dashboard/designers/brands/BrandPage'),
		meta: {
			pageTitle: 'Marcas',
			breadcrumb: [
				{
					text: 'Listado de Marcas',
					active: true,
				},
			],
		},
	},
	{
		path: '/designers/brands/create',
		name: 'designers-brands-create',
		// eslint-disable-next-line import/extensions
		component: () => import('@/pages/dashboard/designers/brands/BrandCreatePage'),
		meta: {
			pageTitle: 'Marcas',
			breadcrumb: [
				{
					text: 'Listado de Marcas',
				},
				{
					text: 'Crear',
					active: true,
				},
			],
		},
	},
	{
		path: '/designers/brands/edit/:id',
		name: 'designers-brands-edit',
		// eslint-disable-next-line import/extensions
		component: () => import('@/pages/dashboard/designers/brands/BrandEditPage'),
		props: route => {
			const id = Number(route.params.id)
			return isNaN(id) ? { id: 1 } : { id }
		},
		meta: {
			pageTitle: 'Marcas',
			breadcrumb: [
				{
					text: 'Listado de Marcas',
				},
				{
					text: 'Editar',
					active: true,
				},
			],
		},
	},
]
