export default [
  {
    path: '/customers-search-advanced',
    name: 'customers-search-advanced',
    // eslint-disable-next-line import/extensions
    component: () => import('@/pages/dashboard/customers-search-advanced/CustomerSearchAdvancedPage'),
    meta: {
      pageTitle: 'Clientes',
      breadcrumb: [
        {
          text: 'Buscador avandado',
          active: true,
        },
      ],
    },
  },
]
