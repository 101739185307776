export default [
  // {
  //   path: '/company/tickets/:company',
  //   name: 'public-tickets',
  //   // eslint-disable-next-line import/extensions
  //   component: () => import('@/pages/public/tickets/TicketPage'),
  //   props: route => {
  //     return {
  //       company: route.params.company,
  //       public: true
  //     }
  //   },
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/company/tickets/:company/search/:token',
  //   name: 'public-tickets-search',
  //   // eslint-disable-next-line import/extensions
  //   component: () => import('@/pages/public/tickets/TicketSearchPage'),
  //   props: route => {
  //     return {
  //       company: route.params.company,
  //       token: route.params.token,
  //       public: true
  //     }
  //   },
  //   meta: {
  //     layout: 'full',
  //   },
  // },
]
